import React, { useRef, useEffect } from 'react';
import './Resume.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { OptionDropdownInner } from '../../atoms/OptionDropdown/OptionDropdown';
import { useGetBorrowers } from '../../../services/borrowers';
import { borrowerDataVar } from '../../../App';

export const Resume = (props) => {
  const { isSelected, setBottomValue, setSelectedIndex, values, setValue, onFinish, isBrokerMode } = props;
  const { isProcessingTeamMode } = props;

  const stepRef = useRef()

  useEffect(() => {
    if (stepRef.current && isSelected) {
      setBottomValue(stepRef.current.offsetTop)
    }
  }, [isSelected, stepRef.current])

  return (
    <div className='step-section resume processing' ref={stepRef}>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-coins.svg"} isBrokerMode={isBrokerMode} />
      </div>
      {
        isProcessingTeamMode ?
          <BorrowerSelectionSection />
          :
          null
      }
      <FooterContent values={values} setSelectedIndex={setSelectedIndex} setValue={setValue} isBrokerMode={isBrokerMode}/>
      <div className='loan-options-button' onClick={onFinish}>
        {
          !isProcessingTeamMode ?
            "Show me my loan options"
            :
            "Show me loan options for this borrower"
        }
      </div>
    </div>
  );
}

const BorrowerSelectionSection = (props) => {
  const { data } = useGetBorrowers()

  console.log({ data })

  const borrowers = data?.getBorrowers?.filter((r) => r.name) || []

  const options = [
    ...borrowers
      .slice(1, 10)
      .map((v) => {
        return {
          value: v.email,
          // label: `${v.name} ${v.lastName}`,
          label: `${v.name}`,
          disabled: false,
          className: undefined,
          raw: v
        }
        }
      )
    ,
    {
      value: "new",
      label: "New Borrower",
      disabled: false,
      className: undefined
    }
  ]

  if (!borrowers.length) {
    return null
  }

  return (
    <p>
      New loan for {" "}
      <div style={{ display: 'inline-block' }}>
        <OptionDropdownInner options={options} defaultValue={options[0]} onChange={((o) => borrowerDataVar(o.raw))}/>
      </div>
    </p>
  )
}