import React, { useState} from 'react';
import '../PreApprovalForm/PreApprovalForm.scss';
import './ProcessingTeamForm.scss';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { FooterContent, SpanOption } from '../ResumeFooter/ResumeFooter';
import { OptionDropdown, OptionDropdownInner } from '../../atoms/OptionDropdown/OptionDropdown';
import { AddressCaptionInner } from '../PreApprovalForm/PreApprovalForm';

export const ProcessingTeamForm = (props) => {
  const { values, onContinue, borrowerData} = props;

  const [firstName, setFirstName] = useState(borrowerData?.name || "")
  const [lastName, setLastName] = useState(borrowerData?.lastName || "")
  const [email, setEmail] = useState(borrowerData?.email || "")
  const [phoneNumber, setPhoneNumber] = useState(borrowerData?.phoneNumber || "")
  const [password] = useState("password")

  const [investmentAddress, setInvestmentAddress] = useState()

  const userData = {
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    investmentAddress
  }

  const [addressSelected, setAddressSelected] = useState(false)

  const titleMarkup = (
    borrowerData ?
    <h1>Confirm the account info and we’ll add the loan to the account</h1>
    :
    <h1>Fill out borrower info and generate account creation email</h1>
  )

  const borrowerName = borrowerData ? `${firstName} ${lastName}` : "new borrower"

  return (
    <div className='pre-approval-form processing'>
      <div className='first-section'>
        <p>New loan for <SpanOption text={borrowerName} /></p>
        {titleMarkup}
        <div className='inputs-container'>
          <TextInput label={"First name"} value={firstName} onChange={(e) => {console.log(e); setFirstName(e)}} />
          <TextInput label={"Last name"} value={lastName} onChange={(e) => setLastName(e)} />
          <TextInput label={"Email address"} value={email} onChange={(e) => setEmail(e)} />
          <TextInput label={"Phone number"} value={phoneNumber} onChange={(e) => setPhoneNumber(e)} />
        </div>

        <div className='property'>
          <TextInput label={"Investment property address"} value={investmentAddress} onChange={(e) => setInvestmentAddress(e)} />
          <AddressCaptionInner {
            ...{ addressSelected, setAddressSelected, caption: "The borrower doesn’t have specific property in mind" }
          } />
        </div>
      </div>

      {
        !addressSelected ?
          <div className='resume-container'>
            <FooterContent values={values} oneLine={true} isSpan={true} />
          </div>
          :
          null
      }

      <div>
        <div className='loan-options-button' onClick={() => onContinue(userData)}>
          Email borrower account creation link
        </div>
        <span className='caption'>Borrower agrees to be contacted by Constitution Lending</span>
      </div>
    </div>
  );
}