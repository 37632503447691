import React, { useEffect, useRef, useState } from 'react';
import { Skeleton, Slider, Tooltip, ConfigProvider } from 'antd';
import isEqual from 'lodash.isequal';

import './LoanBuilder.scss';

import { LoanOption } from '../../atoms/LoanOption/LoanOption';
import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { NumberInput } from '../../atoms/NumberInput/NumberInput';
import { currencyFormatter } from '../../atoms/NumberInput/NumberInput';
import { SendLoanFormContent } from '../SendLoanForm/SendLoanForm';

import {
  isShortTermBridge,
  isShortTermFixAndFlip,
  isLongTermRental,
  isRefinance,
  mapInput,
  loan_computation,
  rental_loan_computation,
  isSingleHome,
  isTwoToFourUnit,
  cannotBePriced
} from '../../../utils/functions';
import { InfoBox } from '../LoanResume/LoanResume';
import { InfoTooltip, InfoTooltipPositioner } from './Tooltip';
import LoanOptionsAvailable from './LoanOptionsAvailable';

import { trackEvent, InputUpdated, trackSectionLoadedEvent } from '../../../utils/mixpanel';

import { toUSD } from '../../../utils/constants';

const defaultValues = {
  "price": 300000,
  "asIsValue": 300000,
  "constructionBudget": 50000,
  "afterRepairValue": 425000,
  "monthlyRent": 2500,
  "annualTaxes": 4000,
  "annualInsurance": 3000,
  "annualHoa": 0,
  "currentLoanBalance": 220000
}

const rentalInputs = [
  {
    labelText: "Estimated monthly rental income",
    label: <React.Fragment>Estimated <b>monthly</b> rental income</React.Fragment>,
    customKey: "monthlyRent"
  },
  {
    labelText: "Estimated annual property taxes",
    label: <React.Fragment>Estimated <b>annual</b> property taxes</React.Fragment>,
    customKey: "annualTaxes"
  },
  {
    labelText: "Estimated annual property insurance",
    label: <React.Fragment>Estimated <b>annual</b> property insurance</React.Fragment>,
    customKey: "annualInsurance"
  },
  {
    labelText: "Estimated annual HOA fee",
    label: <React.Fragment>Estimated <b>annual</b> HOA fee</React.Fragment>,
    customKey: "annualHoa"
  },
]

const getMinLoanAmount = (values) => {
  if (isLongTermRental(values)) {
    return 150000
  } else {
    return 100000
  }
}

const isBelowMinLoanAmount = (loanAmount, values) => {
  return getMinLoanAmount(values) > loanAmount
}

export function getInputData(values) {
  let data = []

  if (isShortTermBridge(values)) {
    if (!isRefinance(values)) {
      data = [
        {
          label: "Purchase Price",
          customKey: "price"
        }
      ]
    } else {
      data = [
        {
          label: "As-Is Value",
          customKey: "price"
        },
        {
          label: "Estimated payoff amount",
          customKey: "currentLoanBalance"
        }
      ]
    }
  } else if (isShortTermFixAndFlip(values)) {
    if (!isRefinance(values)) {
      data = [
        {
          label: "Purchase Price",
          customKey: "price"
        },
        {
          label: "Construction/rehab budget",
          customKey: "constructionBudget"
        },
        {
          label: "Approximate after repair value (ARV)",
          customKey: "afterRepairValue"
        },
      ]
    } else {
      data = [
        {
          label: "As-Is Value",
          customKey: "price"
        },
        {
          label: "Estimated payoff amount",
          customKey: "currentLoanBalance"
        },
        {
          label: "Construction/rehab budget",
          customKey: "constructionBudget"
        },
        {
          label: "Approximate after repair value (ARV)",
          customKey: "afterRepairValue"
        },
      ]
    }
  } else if (isLongTermRental(values)) {
    if (!isRefinance(values)) {
      data = [
        {
          label: "Purchase Price",
          customKey: "price"
        },
        ...rentalInputs
      ]
    } else {
      data = [
        {
          label: "As-Is Value",
          customKey: "price"
        },
        {
          label: "Estimated payoff amount",
          customKey: "currentLoanBalance"
        },
        ...rentalInputs
      ]
    }
  }

  return data
}

const Inputt = ({ label, customKey, values, setValue, isDisabled, labelText, loanAmount }) => {
  return (
    <NumberInput
      label={label}
      value={values[customKey]}
      setValue={(v) => setValue(customKey, v)}
      defaultValue={defaultValues[customKey]}
      error={getErrors({ label, customKey, values, setValue, labelText, loanAmount })}
      isDisabled={isDisabled}
    />
  )
}

function getErrors({ label, customKey, values, labelText, loanAmount }) {
  const value = values[customKey]
  let error = undefined;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  let max, min;
  const minARV = (values["price"] || 0) + (values["constructionBudget"] || 0);
  min = isShortTermFixAndFlip(values) && ["afterRepairValue"].includes(customKey) ? minARV : 15000;
  max = 10000000;

  let l = ""

  try {
    l = label.toLowerCase()
  } catch (e) {
    l = labelText
  }

  if (value === null || value === "") {
    error = <React.Fragment><span>Increase the {l}.</span> The {l} cannot be 0</React.Fragment>;
  } else {
    const isARentalInput = rentalInputs.some(({ customKey: k }) => {
      return k.includes(customKey)
    })

    const isMinExcluded = isARentalInput || ["currentLoanBalance"].includes(customKey)
    const isMaxExclued = ["price", "currentLoanBalance"].includes(customKey)

    if (value < min && !isMinExcluded) {
      error = <React.Fragment><span>Increase the {l}.</span> The {l} cannot be less than {currencyFormatter.format(min)}</React.Fragment>
    } else {
      if (value > max && !isMaxExclued) {
        error = <React.Fragment><span>Decrease the {l}.</span> The {l} cannot be greater than {currencyFormatter.format(max)}</React.Fragment>
      }
    }
  }

  if (["price"].includes(customKey) && loanAmount && values["price"]) {
    if (isBelowMinLoanAmount(loanAmount, values)) {
      if (isShortTermBridge(values)) {
        error = <React.Fragment><span>Increase the {l}.</span> The minimum loan amount is {currencyFormatter.format(getMinLoanAmount(values))}</React.Fragment>
      } else if (isShortTermFixAndFlip(values)) {
        error = <React.Fragment><span>Increase the purchase price / as is value or the construction budget.</span> The minimum loan amount is {currencyFormatter.format(getMinLoanAmount(values))}</React.Fragment>
      } else if (isLongTermRental(values)) {
        error = <React.Fragment><span>Increase the {l}.</span> The minimum loan amount is {currencyFormatter.format(getMinLoanAmount(values))}</React.Fragment>
      }
    } else {
      if (loanAmount > 2000000) {
        error = <React.Fragment><span>Decrease the {l}.</span> The maximum loan amount is {currencyFormatter.format(2000000)}</React.Fragment>
      }
    }
  }

  /* General Errors */

  if (isShortTermFixAndFlip(values)) {
    if (["afterRepairValue"].includes(customKey)) {
      const arv = values["afterRepairValue"]
      if (arv < minARV) {
        error = <React.Fragment><span>Increase the {l}, or decrease the purchase price/as is value or the construction budget.</span> The {l} must be greater than the sum of the purchase price/as is value and the rehab budget</React.Fragment>
      }
    } else if (["constructionBudget"].includes(customKey)) {
      const constructionBudget = values["constructionBudget"]
      const price = values["price"]
      if (constructionBudget && price && constructionBudget/price < 0.05) {
        error = <React.Fragment><span>Increase the {l} or decrease purchase price/as is value.</span> The {l} must be greater than 5% of the purchase price/as is value</React.Fragment>
      } else if (constructionBudget && price && constructionBudget > (1.5*price)) {
        error = <React.Fragment><span>Decrease the {l} or increase purchase price/as is value.</span> The {l} must be less than 150% of the purchase price/as is value</React.Fragment>
      }
    }
  }

  return error;
}

function getOptionRentalData(option, points, caption) {
  return {
    loanTerm: "30 years",
    originationPoints: points,
    rate: (option.total_rate * 100).toFixed(3),
    monthlyPayment: option.monthly_payment,
    caption,
    totalLoanAmount: option.loan_amount,
    ltv: option.ltv,
    buydownPoints: points,
    cashoutAmount: option.cashout_amount,
    dscr: option.dscr,
    programName: !option.is_extended ? "Standard" : "Extended",
    bondPrice: option.bond_price
  }
}

function getOptionShortTermData(option, points, caption) {
  return {
    loanTerm: "12 months",
    originationPoints: points,
    rate: (option.rate * 100).toFixed(2),
    monthlyPayment: option.monthly_payment,
    caption,
    totalLoanAmount: option.advance_amount_max || option.total_loan_amount,
    ltv: option.ltv,
    buydownPoints: points,
    cashoutAmount: option.cashout_amount,
    ltc: option.ltc
  }
}


export const LoanBuilder = (props) => {
  const { values, setValues, setValue, onSelect, isBrokerMode } = props;

  const [loanOptions, setLoanOptions] = useState(undefined)
  const [multipleLoanOptions, setMultipleLoanOptions] = useState(undefined)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    trackSectionLoadedEvent('Loan Builder')
  }, [])

  /* Loan balance variables */
  const maxLoanBalance = useRef()
  const [loanBalance, setLoanBalance] = useState()
  
  const maxLTV = useRef()
  const max_ltv = maxLTV.current
  let [ltv, setLtv] = useState()

  /* We keep state of the old values */
  const lastValues = useRef()
  const lastLoanBalance = useRef()
  const lastLtv = useRef()

  const {loanBalance: _, ...coreValues} = values
  const {loanBalance: __, ...lastCoreValues} = lastValues.current || {}

  /* Some changes were made in the options, we need to clear */
  const valuesHaveChanged = !isEqual(values, lastValues.current)
  const coreValuesHaveChanged = !isEqual(coreValues, lastCoreValues)

  /* When values change we trigger the call to the API */
  useEffect(() => {
    if (!lastValues.current) {
      lastValues.current = values
      return
    }
    
    if (coreValuesHaveChanged) {
      console.log({coreValuesHaveChanged, coreValues, lastCoreValues})
      delete values["loanBalance"]
    }

    if (valuesHaveChanged) {
      console.log({ valuesHaveChanged, values, lastValues: lastValues.current})
      const changedKey = Object.keys(values).find((k) => values[k] !== lastValues.current[k])
      trackEvent(InputUpdated(changedKey, values[changedKey]))
    }

    /* And we need to keep track of record */
    lastValues.current = values

    if (rental_loan_computation && loan_computation && valuesHaveChanged) {
      if (!isRefinance(values)) {
        delete values["currentLoanBalance"]
      }

      setIsLoading(true)
      setLoanOptions(null)
      if (isLongTermRental(values)) {
        rental_loan_computation(
          /* If there is loan balance there is not an optimization */
          mapInput({...values}),
          (results) => {
            results = results[0]
            setMultipleLoanOptions(results)
            if (results) {
              const result = results[0]
              /* We store and update the data */
              setLoanOptions(result)
              setLtv(result.ltv)
              setLoanBalance(result.loan_amount)
              /* We keep track of the maximum on the call for those specific values */
              /* We can do as many calls as we want after this, when loan amount changes */
              /* And loan amount changes when LTV is updated, or when it's directly updated */
              if (!maxLoanBalance.current || coreValuesHaveChanged) {
                maxLoanBalance.current = (result.loan_amount)
              }
              if (!maxLTV.current || coreValuesHaveChanged) {
                maxLTV.current = (result.ltv)
              }
              /* We also update this so it does not trigger a rerender */
              lastLoanBalance.current = result.loan_amount
              lastLtv.current = result.ltv
            }
            setIsLoading(false)
          }
        )
      } else {
        loan_computation(
          {...values},
          (results) => {
            results = results[0]
            setMultipleLoanOptions(results)
            if (results) {
              const result = results[0]
              const newLoanBalance = result.total_loan_amount || result.advance_amount_max
              /* We store and update the data */
              setLoanOptions(result)
              setLtv(result.ltv)
              setLoanBalance(newLoanBalance)
              /* We keep track of the maximum on the call for those specific values */
              /* We can do as many calls as we want after this, when loan amount changes */
              /* And loan amount changes when LTV is updated, or when it's directly updated */
              if (!maxLoanBalance.current || coreValuesHaveChanged) {
                maxLoanBalance.current = (newLoanBalance)
              }
              if (!maxLTV.current || coreValuesHaveChanged) {
                maxLTV.current = result.ltv
              }
              /* We also update this so it does not trigger a rerender */
              lastLoanBalance.current = newLoanBalance
              lastLtv.current = result.ltv
            }
            setIsLoading(false)
          }
        )
      }
    }
  }, [values, rental_loan_computation, loan_computation])

  /* Updating loan balance triggers fetch */
  useEffect(() => {
    console.log({loanBalance, lastLoanBalance: lastLoanBalance.current, maxLoanBalance: maxLoanBalance.current})
    if (!lastLoanBalance.current) {
      lastLoanBalance.current = loanBalance
      return
    } else {
      if (loanBalance !== undefined && loanBalance !== lastLoanBalance.current) {
        console.log("Adding loan balance to values ...")
        setValues({...values, loanBalance})
      }
      lastLoanBalance.current = loanBalance
    }
  }, [loanBalance])

  /* Updating LTV updates the loan amount */
  useEffect(() => {
    console.log({ltv, max_ltv})
    if (!lastLtv.current) {
      lastLtv.current = ltv
      return
    } else {
      if (ltv !== undefined && ltv !== lastLtv.current) {
        let newBalance = values["price"] * ltv
        if (isBelowMinLoanAmount(newBalance, values)) {
          newBalance = getMinLoanAmount(values)
        }
        setLoanBalance(isShortTermFixAndFlip(values) ? newBalance + values["constructionBudget"]: newBalance)
      }
      lastLtv.current = ltv
    }
  }, [ltv])

  const isRental = isLongTermRental(values)

  const options = (
    (!isRental && loanOptions) || (isRental && multipleLoanOptions?.length)
      ?
      (
        isRental ?
          [
            getOptionRentalData(multipleLoanOptions[2], 2, "LOWEST RATE"),
            getOptionRentalData(multipleLoanOptions[1], 1),
            getOptionRentalData(multipleLoanOptions[0], 0, "LOWEST CASH TO CLOSE")
          ]
          :
          [
            getOptionShortTermData(multipleLoanOptions[2], 2, "LOWEST RATE"),
            getOptionShortTermData(multipleLoanOptions[1], 1),
            getOptionShortTermData(multipleLoanOptions[0], 0, "LOWEST CASH TO CLOSE")
          ]
      )
      :
      []
  )

  /* Default input values */
  const inputData = React.useMemo(() => getInputData(coreValues), [coreValuesHaveChanged])
  const lastInputData = useRef(null)
  useEffect(() => {
    const isFirstCall = !lastInputData.current
    const hasChanged = !isEqual(inputData, lastInputData.current)
    if (isFirstCall || (!isFirstCall && hasChanged)) {
      console.log('Setting default values')
      const defaults = inputData.reduce((acc, { customKey }) => ({...acc, [customKey]: defaultValues[customKey]}), {})
      setValues({
        ...defaults,
        ...values
      })
    }
    lastInputData.current = inputData
  }, [inputData])

  const errors = inputData.map((v) => getErrors({ ...v, key: v.customKey, values, setValue, loanAmount: maxLoanBalance.current }))
  const hasError = errors.some((v) => v !== undefined)

  const isPricerDisabled = cannotBePriced(values)

  /* Input Elements */
  const elements = inputData.map((v) => <Inputt  {...v} key={v.customKey} values={values} setValue={setValue} isDisabled={isPricerDisabled} loanAmount={loanBalance} />)

  /* Input Elements - DSCR */
  if (isLongTermRental(values)) {
    const text = "A ratio of your rental income to PITI (costs associated with running a rental). Affects the max LTV we can give."
    
    const DSCRElement = ({dscr}) => (
      <div className='dscr'>DSCR: <span>{dscr ? (dscr).toFixed(2) : "___"}</span><InfoTooltipPositioner><InfoTooltip title={text} placement={"top"} isDisabled={isPricerDisabled}/></InfoTooltipPositioner></div>
    )

    const dscr = loanOptions?.dscr

    const tooltipText = "A ratio of your rental income to PITI (costs associated with running a rental). Affects the max LTV we can give."
    // Add logic for alternative open tooltip

    elements.splice(isRefinance(values) ? 2 : 1, 0, <DSCRElement dscr={dscr} />);
  }

  /* Input Element - ARV LTV Slider */
  const [maxLTVPosition, setMaxLTVPosition] = useState(null)

  useEffect(() => {
    setMaxLTVPosition(null)
  }, [max_ltv])

  const aRequestToTheServerHasBeenMade = multipleLoanOptions !== undefined
  const thereIsAFaultyServerResponse = multipleLoanOptions !== undefined && loanOptions === null
  const isRefreshing = isLoading || coreValuesHaveChanged

  const loanCantBeAutopriced = !isRefreshing && aRequestToTheServerHasBeenMade && (thereIsAFaultyServerResponse || ((loanOptions && options.length === 0) || hasError || !(loanOptions && loanOptions.monthly_payment) || !loanOptions)) || cannotBePriced(values) || isBelowMinLoanAmount(loanBalance, values)

  if (isShortTermFixAndFlip(values) && ltv && !(cannotBePriced(values) || loanCantBeAutopriced)) {
    if (!isLoading) {
      elements.splice(isRefinance(values) ? 4 : 3, 0, <div><ARVLTVSlider ltv={ltv} max_ltv={maxLTV.current} setLtv={setLtv} maxLTVPosition={maxLTVPosition} setMaxLTVPosition={setMaxLTVPosition}/></div>);
    }
  }

  const getLoanErrors = () => {
    return [
      ...errors.filter(Boolean)
    ]
  }

  const InputSkeleton = () => (<Skeleton.Input style={{ display: 'block', width: '100%', marginBottom: "25px", height: "92px", borderRadius: "10px" }}/>)

  const className = isPricerDisabled ? 'loan-builder disabled' : 'loan-builder'

  return (
    <React.Fragment>
      <div className={className}>
        <div className='loan-rate-section'>
          <div className='loan-rate'>
            <h1>Estimate your rate</h1>
            <InfoBox />
            <div className='loan-inputs'>
              {elements}
              <div style={{ height: "20px" }}></div>
            </div>
            {
              isLongTermRental(values) ?
                <div className='scroll-arrow'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="29" viewBox="0 0 8 29" fill="none">
                    <path d="M3.64645 28.3536C3.84171 28.5488 4.15829 28.5488 4.35355 28.3536L7.53553 25.1716C7.7308 24.9763 7.7308 24.6597 7.53553 24.4645C7.34027 24.2692 7.02369 24.2692 6.82843 24.4645L4 27.2929L1.17157 24.4645C0.976311 24.2692 0.659728 24.2692 0.464466 24.4645C0.269204 24.6597 0.269204 24.9763 0.464466 25.1716L3.64645 28.3536ZM3.5 0L3.5 28H4.5L4.5 0L3.5 0Z" fill="#122536"/>
                  </svg>  
                </div>
                :
                null
            }
          </div>
          <div className='loan-resume'>
            {/* <h3>Try a different loan type</h3> */}
            <FooterContent values={values} setValue={setValue} oneLine={true} firstPerson isBrokerMode={isBrokerMode} />
          </div>
        </div>
        <div className='loan-options-section'>
          <h1>Loan Options</h1>
          <div className='loan-options-container'>
            {
              isLoading ?
                <React.Fragment>
                  <InputSkeleton />
                  <InputSkeleton />
                  <InputSkeleton />
                </React.Fragment>
                :
                loanCantBeAutopriced ?
                  (
                    cannotBePriced(values) ?
                      <SendLoanFormContent title={"We can’t automatically price this loan"} />
                      :
                      <LoanOptionsAvailable errors={getLoanErrors()}/>
                  )
                  :
                  options.map((option, index) => <LoanOption key={JSON.stringify(option)} {...option} onSelect={() => onSelect(
                    {
                      ...option,
                      nonSelectedOptions: [0,1,2].filter(i => i !== index).map(i => options[i])
                    }
                  )} />)
            }
          </div>
          {
            !loanOptions || (isLoading || loanCantBeAutopriced || hasError) ?
              null
              :
              <div className='loan-details-container'>
                <LoanDetails
                  max_ltv={max_ltv}
                  ltv={ltv}
                  setLtv={setLtv}
                  arv_ltv={loanOptions?.arv_ltv}
                  ltc={loanOptions?.ltc}
                  isLoading={isLoading}
                  loanBalance={loanBalance}
                  setLoanBalance={setLoanBalance}
                />
              </div>
          }
        </div>
      </div>
      <MobileLoanBuilder
        isLoading={!lastInputData.current || isLoading}
        values={values}
        setValue={setValue}
        isBrokerMode={isBrokerMode}
        inputElements={elements}
        options={options}
        onSelect={onSelect}
        max_ltv={max_ltv}
        ltv={ltv}
        setLtv={setLtv}
        arv_ltv={loanOptions?.arv_ltv}
        ltc={loanOptions?.ltc}
        loanBalance={loanBalance}
        maxLTVPosition={maxLTVPosition}
        setMaxLTVPosition={setMaxLTVPosition}
        loanCantBeAutopriced={loanCantBeAutopriced}
        isPricerDisabled={isPricerDisabled}
        errors={getLoanErrors()}
      />
    </React.Fragment>
  );
}

export const Input = (props) => {
  const { label, value, onChange } = props;
  const [innerValue, setInnerValue] = useState(value || "")

  useEffect(() => {
    if (innerValue != value) {
      onChange(innerValue)
    }
  }, [innerValue, value])

  return (
    <div className='loan-input'>
      <p>{label}</p>
      <input value={innerValue} onChange={(event) => setInnerValue(event.target.value) } />
    </div>
  )
}

const DownArrowSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
    <path d="M11 1L6 6L1 1" stroke="#48759A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const AdjustLoanAmount = ({ loanBalance, setLoanBalance }) => {
  const [isSelected, setIsSelected] = useState(false)

  const [innerLoanBalance, setInnerLoanBalance] = useState(loanBalance)

  useEffect(() => {
    setInnerLoanBalance(loanBalance)
  }, [loanBalance])

  const ConfirmationSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="8" fill="#F5FAF7" stroke="#587E5B" />
      <path d="M13.8889 6L7.77778 12.1111L5 9.33333" stroke="#587E5B" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  const toggleIsSelected = () => {
    if (isSelected) {
      setLoanBalance(innerLoanBalance)
    }
    setIsSelected(!isSelected)
  }

  return (
    <div className='adjust-loan-amount'>
      {
        !isSelected ?
          <div className='label' onClick={toggleIsSelected}>
            Adjust Loan Amount
            <div className='arrow-container'>
              <DownArrowSVG/>
            </div>
          </div>
          :
          <div>
            <NumberInput {
              ...{
                value: innerLoanBalance,
                setValue: setInnerLoanBalance
              }}/>
              <div className='svg-container' onClick={toggleIsSelected}><ConfirmationSVG /></div>
          </div>
      }
    </div>
  )
}

const AdjustLTVAmount = (props) => {
  let { ltv, max_ltv, setLtv: _setLtv, clean } = props
  max_ltv = max_ltv*100
  ltv = Math.ceil(ltv*100)

  const setLtv = (v) => {
    if (v >= max_ltv) {
      v = max_ltv
    }

    if (v <= max_ltv) {
      _setLtv(v/100)
    }
  }

  const [innerLTV, _setInnerLTV] = useState(ltv)

  useEffect(() => {
    if (!innerLTV) {
      setInnerLTV(ltv)
    }
  }, [ltv])

  const setInnerLTV = (v) => {
    v = Math.round(v / 5) * 5

    if (v >= Math.ceil(max_ltv)) {
      v = Math.ceil(max_ltv)
    }

    if (v <= 5) {
      v = 5
    }

    if (v <= Math.ceil(max_ltv)) {
      _setInnerLTV(v)
    }
  }

  const [isSelected, setIsSelected] = useState(false)

  const toggleIsSelected = () => {
    setIsSelected(!isSelected)
  }

  console.log({ltv, max_ltv, innerLTV})

  if (clean) {
    return (
      <div className='adjust-loan-amount adjust-ltv-amount'>
        <div>
          <Tooltip placement="right" title={`Max LTV: ${Math.ceil(max_ltv)}%`} open autoAdjustOverflow={false} overlayClassName='light' >
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    /* here is your component tokens */
                    trackBg: "#355C7D",
                    trackHoverBg: "#355C7D",
                    trackBgDisabled: "#CCD3DB",
                    handleActiveColor: "#355C7D",
                    handleColor: "#355C7D",
                    handleLineWidth: 3,
                    handleLineWidthHover: 4,
                    handleSize: 10,
                    handleSizeHover: 12,
                  },
                  Tooltip: {
                    colorBgSpotlight: "#355C7D",
                    colorTextLightSolid: "#F5FAFF",
                    fontFamily: "Inter",
                    fontSize: 10,

                  }
                },
              }}
            >
              <Slider
                value={innerLTV}
                onChange={setInnerLTV}
                onAfterChange={setLtv}
                defaultValue={ltv}
                tooltip={{ open: true, formatter: (v) => `${v}%` }}
              />
            </ConfigProvider>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <div className='adjust-loan-amount adjust-ltv-amount'>
      {
        !isSelected ?
          <div className='label' onClick={toggleIsSelected}>
            Adjust LTV
            <div className='arrow-container'>
              <DownArrowSVG/>
            </div>
          </div>
          :
          <div>
            <Tooltip placement="right" title={`Max LTV: ${Math.ceil(max_ltv)}%`} open autoAdjustOverflow={false} overlayClassName='light' >
              <ConfigProvider
                theme={{
                  components: {
                    Slider: {
                      /* here is your component tokens */
                      trackBg: "#355C7D",
                      trackHoverBg: "#355C7D",
                      trackBgDisabled: "#CCD3DB",
                      handleActiveColor: "#355C7D",
                      handleColor: "#355C7D",
                      handleLineWidth: 3,
                      handleLineWidthHover: 4,
                      handleSize: 10,
                      handleSizeHover: 12,
                    },
                    Tooltip: {
                      colorBgSpotlight: "#355C7D",
                      colorTextLightSolid: "#F5FAFF",
                      fontFamily: "Inter",
                      fontSize: 10,

                    }
                  },
                }}
              >
                <Slider
                  value={innerLTV}
                  onChange={setInnerLTV}
                  onAfterChange={setLtv}
                  defaultValue={ltv}
                  tooltip={{ open: true, formatter: (v) => `${v}%` }}
                />
              </ConfigProvider>
            </Tooltip>
          </div>
      }
    </div>
  )
}

const ARVLTVSlider = ({ ltv, max_ltv, setLtv: _setLtv, maxLTVPosition, setMaxLTVPosition }) => {
  max_ltv = max_ltv*100
  ltv = Math.ceil(ltv*100)

  const setLtv = (v) => {
    if (v >= max_ltv) {
      v = max_ltv
    }

    if (v <= max_ltv) {
      _setLtv(v/100)
    }
  }

  const [innerLTV, _setInnerLTV] = useState(ltv)

  const setInnerLTV = (v) => {
    v = Math.round(v / 5) * 5

    if (v >= Math.ceil(max_ltv)) {
      v = Math.ceil(max_ltv)
    }

    if (v <= 5) {
      v = 5
    }

    if (v <= Math.ceil(max_ltv)) {
      _setInnerLTV(v)
    }
  }

  const node = useRef()
  
  const [isPositioned, setIsPositioned] = useState(false)
  const tooltip = useRef()

  useEffect(() => {
    if (node.current && tooltip.current) {
      const container = node.current

      let tooltipNode = container.querySelector('.light')
      let sliderHandleNode = container.querySelector('.ant-slider-handle')
      let intervalId = null

      const watcher = async () => {
        intervalId = setInterval(async () => {
          console.log('watching')
          if (tooltipNode) {
            if (tooltipNode && sliderHandleNode) {
              if (!maxLTVPosition) {
                let sliderHandleNodeLeft = window.getComputedStyle(sliderHandleNode).getPropertyValue('left');
                const maxLTVPosition = `${parseInt(sliderHandleNodeLeft) - 41}px`;
                tooltipNode.style.left = maxLTVPosition
                tooltipNode.style.visibility = "visible"
                setMaxLTVPosition(maxLTVPosition)
              } else {
                tooltipNode.style.left = maxLTVPosition;
                tooltipNode.style.visibility = "visible"
              }
              setIsPositioned(true)
            }
            clearInterval(intervalId);
          } else {
            tooltipNode = container.querySelector('.light')
            sliderHandleNode = container.querySelector('.ant-slider-handle')
          }
        }, 200);
      };

      watcher();

      return () => {
        if (intervalId) {
          clearInterval(intervalId)
        }
      }
    }
  }, [tooltip.current])

  return (
    <div className='arv-slider' style={{ visibility: (isPositioned ? "visible" : "hidden") }} ref={node}>
      <span>Desired as-is LTV</span>
      <div style={{ padding: "4px" }}>
        <ConfigProvider
          theme={{
            components: {
              Tooltip: {
                colorBgSpotlight: "#355C7D",
                colorTextLightSolid: "#F5FAFF",
                fontFamily: "Inter",
                fontSize: 10,
              }
            },
          }}
        >
          <Tooltip
            zIndex={0}
            ref={tooltip}
            placement="topRight"
            title={`Max LTV: ${Math.ceil(max_ltv)}%`}
            open
            autoAdjustOverflow={true}
            overlayClassName='light arv-tooltip'
            getPopupContainer={() => node.current}
          >
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    /* here is your component tokens */
                    trackBg: "#355C7D",
                    trackHoverBg: "#355C7D",
                    trackBgDisabled: "#CCD3DB",
                    handleActiveColor: "#355C7D",
                    handleColor: "#355C7D",
                    handleLineWidth: 3,
                    handleLineWidthHover: 4,
                    handleSize: 10,
                    handleSizeHover: 12,
                  }
                },
              }}
            >
              <Slider
                value={innerLTV}
                onChange={setInnerLTV}
                onAfterChange={setLtv}
                tooltip={{ open: true, placement: "bottom", formatter: (v) => `${v}%`, getPopupContainer: () => node.current}}
              />
            </ConfigProvider>
          </Tooltip>
        </ConfigProvider>
      </div>
    </div>
  )
}

export const LoanDetails = (props) => {
  const { max_ltv, ltv, arv_ltv, ltc, isLoading, loanBalance, setLoanBalance, setLtv } = props;

  const loanAmountAdjuster = React.useMemo(
    () => <AdjustLoanAmount loanBalance={loanBalance} setLoanBalance={setLoanBalance} />,
    [loanBalance, setLoanBalance]
  )

  const ltvAdjuster = React.useMemo(
    () => <AdjustLTVAmount ltv={ltv} max_ltv={max_ltv} setLtv={setLtv} />,
    [max_ltv, ltv, setLtv]
  )

  return (
    <div className='loan-details'>
      <div>
        <span>
          Total loan amount
        </span>
        <span>
          {
            isLoading ?
              <Skeleton.Input />
              :
              <React.Fragment>
                <span>{`$${currencyFormatter(loanBalance).split(".")[0]}`}</span>
                {loanAmountAdjuster}
              </React.Fragment>
          }
        </span>
        
      </div>
      {
        isLoading ?
        null
        :
        arv_ltv ?
          <div>
            <div className='arv'>
              <div>
                <span>
                  ARV LTV{" "}
                  <InfoTooltip title={"To adjust ARV LTV, change desired as-is LTV on the left"} placement={"top"}/>
                </span>
                <span>
                  {
                    isLoading ?
                      <Skeleton.Input />
                      :
                      `${(arv_ltv * 100).toFixed(0)}%`
                  }
                </span>
              </div>
              <div>
                <span>
                  LTC{" "}
                  <InfoTooltip title={"To adjust LTC, change desired as-is LTV on the left"} placement={"top"}/>
                </span>
                <span>
                  {
                    isLoading ?
                      <Skeleton.Input />
                      :
                      `${(ltc * 100).toFixed(1)}%`
                  }
                </span>
              </div>
            </div>
            <div className='loan-details-caption'>
              Use left slider to adjust as-is LTV
            </div>
          </div>
          :
          <div>
            <span className='ltv-label'>
              LTV{" "}
              <InfoTooltip title={"To adjust LTV, change the numbers on the left or use the slider"} placement={"top"}/>
            </span>
            <span>
              {
                isLoading ?
                  <Skeleton.Input />
                  :
                  <React.Fragment>
                    <span>{`${(ltv * 100).toFixed(0)}%`}</span>
                    {ltvAdjuster}
                  </React.Fragment>
              }
            </span>
          </div>
      }
    </div>
  )
}

/* Mobile */
const TitleLoanBuilder = () => (
  <div className='loan-rate'>
    <h1>Estimate your rate</h1>
  </div>
)

const LoanResumeLoanBuilder = (props) => {
  const { values, setValue, isBrokerMode } = props

  return (
    <div className='resume-container'>
      <FooterContent values={values} setValue={setValue} oneLine={true} firstPerson isBrokerMode={isBrokerMode} />
    </div>
  )
}

const NoLoanOptionsMobile = ({ errors }) => (
  <div className='no-loan-options'>
    <h1>No loan options available</h1>
    <p>Please fix the following or contact us at <a href="mailto:lend@constlending.com">lend@constlending.com</a></p>
    {
      errors && errors.length > 0 ?
        <ul>
          <li>{errors[0]}</li>
        </ul>
        :
        null
    }
  </div>
)

const LoanDetailsMobile = (props) => {
  let { ltv, arv_ltv, ltc, isLoading, loanBalance, values, loanCantBeAutopriced } = props;

  ltv = isNaN(ltv) ? 0 : ltv
  arv_ltv = isNaN(arv_ltv) ? 0 : arv_ltv
  ltc = isNaN(ltc) ? 0 : ltc
  loanBalance = isNaN(loanBalance) || loanCantBeAutopriced ? 0 : loanBalance

  return (
    <div className='loan-details-mobile'>
      <div className='loan-details'>
        {
          isShortTermFixAndFlip(values) ?
            <div>
              <div className='arv'>
                <div>
                  <span>
                    ARV LTV{" "}
                    <InfoTooltip title={"To adjust ARV LTV, change Desired as-is LTV at the bottom"} placement={"top"} />
                  </span>
                  <span>
                    {
                      isLoading ?
                        <Skeleton.Input />
                        :
                        `${(arv_ltv * 100).toFixed(0)}%`
                    }
                  </span>
                </div>
                <div>
                  <span>
                    LTC{" "}
                    <InfoTooltip title={"To adjust LTC, change Desired as-is LTV at the bottom"} placement={"top"} />
                  </span>
                  <span>
                    {
                      isLoading ?
                        <Skeleton.Input />
                        :
                        `${(ltc * 100).toFixed(1)}%`
                    }
                  </span>
                </div>
              </div>
            </div>
            :
            <div>
              <span className='ltv-label'>
                LTV{" "}
                <InfoTooltip title={"To adjust LTV, change the numbers at the bottom or use the slider"} placement={"top"} />
              </span>
              <span>
                {
                  isLoading ?
                    <Skeleton.Input />
                    :
                    <React.Fragment>
                      <span>{`${(ltv * 100).toFixed(0)}%`}</span>
                    </React.Fragment>
                }
              </span>
            </div>
        }
        <div>
          <span>
            Loan amount
          </span>
          <span>
            <React.Fragment>
              {
                isLoading ?
                  <Skeleton.Input />
                  :
                  <span>{`$${currencyFormatter(loanBalance).split(".")[0]}`}</span>
              }
            </React.Fragment>
          </span>
        </div>
      </div>
      {
        isShortTermFixAndFlip(values) ?
          <div className='loan-details-caption'>
            Use bottom slider to adjust as-is LTV
          </div>
          :
          null
      }
    </div>
  )
}

const InputList = (props) => {
  let { inputElements, values, isLoading, loanCantBeAutopriced } = props;

  inputElements = [...inputElements]

  if (!isLoading) {
    if (isShortTermFixAndFlip(values)) {
      if (!loanCantBeAutopriced) {
        inputElements.pop()
      }
      inputElements.push(
        loanCantBeAutopriced ?
          null
          :
          <div className='loan-input placeholder'>
            <p>Desired as-is LTV</p>
            <AdjustLTVAmount {...props} clean />
          </div>
      );
    } else {
      inputElements.push(
        loanCantBeAutopriced ?
          null
          :
          <div className='loan-input placeholder'>
            <p>Adjust LTV</p>
            <AdjustLTVAmount {...props} clean />
          </div>
      );
    }
  }

  return (
    <div className='loan-inputs'>
      {inputElements}
    </div>
  )
}

const SelectOptionsMobile = (props) => {
  const { isLoading, onSelect } = props
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)

  const options = [...props.options]
  options.reverse()
  const selectedOption = options[selectedOptionIndex]

  const { rate, monthlyPayment, originationPoints } = selectedOption || {}

  const showSkeleton = isLoading || !selectedOption

  const getOptionItemClassName = (currentIndex, selectedIndex) => {
    return currentIndex === selectedIndex ? 'option-item selected' : 'option-item';
  };

  const optionListItems = [
    "LOWEST CASH",
    "BALANCED OPTION",
    "BEST RATE",
  ].map((label, i) => (
    <div className={getOptionItemClassName(i, selectedOptionIndex)} onClick={() => setSelectedOptionIndex(i)}>{label}</div>
  ))

  return (
    <div className='option-selector'>
      <div className='option-list'>
        {optionListItems}
      </div>
      <div className='option-selected'>
        <div className='option-details'>
          <div className='rate'>
            <div>
              <span>Rate</span>
              {
                showSkeleton ?
                  <Skeleton.Input />
                  :
                  <span>{rate}%</span>
              }
            </div>
          </div>
          <div className='monthly-payment'>
            <div>
              <span>Monthly Payment <InfoTooltip title={"This doesn’t include taxes and insurance"} variant={"gray"}/></span>
              {
                showSkeleton ?
                  <Skeleton.Input />
                  :
                  <span>{toUSD(monthlyPayment)}</span>
              }
            </div>
          </div>
          <div className='buydown'>
            <div>
              <span>Buydown <InfoTooltip title={"Pay a percentage of the loan amount upfront to decrease the interest rate over the life of the loan"} variant={"gray"}/></span>
              {
                showSkeleton ?
                  <Skeleton.Input />
                  :
                  <span>{Number(originationPoints).toFixed(2)}%</span>
              }
            </div>
          </div>
        </div>
        <div
          className='option-select-button'
          onClick={() => onSelect(
            {
              ...selectedOption,
              nonSelectedOptions: [0,1,2].filter(i => i !== selectedOptionIndex).map(i => options[i])
            }
          )}
        >
          Select this rate
        </div>
      </div>
    </div>
  )
}

const MobileLoanBuilder = (props) => {
  const className = props.isPricerDisabled ? 'loan-builder disabled' : 'loan-builder'

  return (
    <div className={`${className} loan-builder-mobile`}>
      <div className='content'>
        <TitleLoanBuilder />
        <LoanResumeLoanBuilder {...props} />
        {
          props.loanCantBeAutopriced && cannotBePriced(props.values) ?
            <SendLoanFormContent title={"We can’t automatically price this loan"} />
            :
            <React.Fragment>
              <LoanDetailsMobile {...props} />
              <InputList {...props}/>
              <div className='input-space' />
            </React.Fragment>
        }
      </div>
      {
        props.loanCantBeAutopriced ?
          (
            !cannotBePriced(props.values) ?
              <NoLoanOptionsMobile {...props} />
              :
              null
          )
          :
          <SelectOptionsMobile {...props} />
      }
    </div>
  )
}