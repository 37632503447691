import React, { useState } from 'react';
import './OptionDropdown.scss';
import { Select } from 'antd';
import { ONBOARDING_STEPS } from '../../../utils/constants';
import { getText } from '../../molecules/ResumeFooter/ResumeFooter';
import { trackEvent, QuestionsAnsweredFromDropdown } from '../../../utils/mixpanel';

export const OptionDropdown = (props) => {
  const { values, setValue, index, isBrokerMode } = props;

  const stepText = ONBOARDING_STEPS[index].text
  const stepOptions = ONBOARDING_STEPS[index].options
  let stepCurrentValue = values[stepText]

  const selectedOption = stepOptions.find(op => op.text === stepCurrentValue)
  const isSpecial = selectedOption?.isSpecial

  let options = (
    stepOptions
      .map(({ text, isDisabled, isSpecial }) => ({
        value: text,
        label: getText(values, text, index),
        disabled: isDisabled,
        className: isSpecial ? 'special' : undefined
      })
    )
  )

  if (index === 1) {
    if (!values[ONBOARDING_STEPS[2].text]) {
      stepCurrentValue = ""
      options = [
        {
          value: "",
          label: "bridge or rental"
        }
      ]
    } else {
      const isBridge = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[0].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[0].text
      )
      const isRental = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[0].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[1].text
      )
      const isFix = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[1].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[0].text
      )

      if (isBridge) {
        stepCurrentValue = "bridge"
      } else if (isRental) {
        stepCurrentValue = "rental"
      } else if (isFix) {
        stepCurrentValue  = "fix"
      }

      options = [
        {
          value: "bridge",
          label: "12-month bridge"
        },
        {
          value: "fix",
          label: "12-month fix-and-flip"
        },
        {
          value: "rental",
          label: "30-year rental"
        }
      ]
    }
  }

  const onChange = (value) => { 
    if (index === 1) {
      if (value === "rental") {
        setValue(ONBOARDING_STEPS[2].text, ONBOARDING_STEPS[2].options[1].text);
        trackEvent(QuestionsAnsweredFromDropdown(ONBOARDING_STEPS[2].text, ONBOARDING_STEPS[2].options[1].text));
      } else if (value === "bridge") {
        setValue(ONBOARDING_STEPS[1].text, ONBOARDING_STEPS[1].options[0].text);
        trackEvent(QuestionsAnsweredFromDropdown(ONBOARDING_STEPS[1].text, ONBOARDING_STEPS[1].options[0].text));
      } else if (value === "fix") {
        setValue(ONBOARDING_STEPS[1].text, ONBOARDING_STEPS[1].options[1].text);
        trackEvent(QuestionsAnsweredFromDropdown(ONBOARDING_STEPS[1].text, ONBOARDING_STEPS[1].options[1].text));
      }
    } else {
      setValue(stepText, value);
      trackEvent(QuestionsAnsweredFromDropdown(stepText, value));
    }
  }

  return (
    <OptionDropdownInner
      {
        ...{
          isDisabled: (index === 1 && !values[ONBOARDING_STEPS[2].text]),
          isSpecial,
          isBrokerMode,
          defaultValue: stepCurrentValue,
          value: stepCurrentValue,
          options,
          onChange
        }
      }
    />
  )
}

export const OptionDropdownInner = (props) => {
  const { isSpecial, defaultValue, value, isBrokerMode, options, onChange, customWidth, isDisabled } = props

  const specialClassNameFactory = (className) => (
    isSpecial === true ? `${className} special` : className
  )

  const brokerClassNameFactory = (className) => (
    isBrokerMode === true ? `${className} broker` : className
  )

  const customClassName = `option-dropdown-${Math.trunc(Math.random()*10000)}`
  
  const className = (
    brokerClassNameFactory(specialClassNameFactory(customClassName))
  )

  const onDropdownVisibleChange = () => {
    let DROPDOWN_WIDTH = customWidth;
    const MOBILE_BREAKPOINT = 480;
    
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      DROPDOWN_WIDTH = "60vw"
    } else {
      DROPDOWN_WIDTH = "300px"
    }
    
    setTimeout(() => {
      const elements = document.querySelectorAll(`.ant-select-dropdown.${customClassName}`)
      Array.from(elements).forEach((el) => {
        el.style.width = DROPDOWN_WIDTH;
        el.style.zIndex = 30000;
      })
    }, 0);
  }

  return (
    <span className={ !isSpecial ? 'option' : 'option special'}>
      <Select
        disabled={isDisabled}
        defaultValue={defaultValue}
        value={value}
        popupClassName={className}
        onDropdownVisibleChange={onDropdownVisibleChange}
        options={options}
        onChange={onChange}
      />
    </span>
  )
}